.news-container {
    text-align: center;
    font-family: Roboto, Arial, Helvetica, sans-serif;
}

.no-articles {
    width: 250px;
    max-width: 85%;
    box-sizing: border-box;
    margin: 4em auto;
    background: #fdfdfd;
    border: 1px solid #eaeaea;
    text-align: center;
    padding: 20px;
    text-transform: uppercase;
    color: #888;
    font-weight: 600;
    letter-spacing: .7px;
    border-radius: 5px;
}

.articles, .article-preview-container {
    display: inline-block;
    position: relative;
}

.articles {
    text-align: left;
}

.hide {
    display: none;
}

.dark-background {
    width: 100vw;
    height: 100vh;
    position: fixed;
    background: #000000de;
    top: 0;
    left: 0;
}