@import url('https://fonts.googleapis.com/css?family=Lato:400,700');
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500');



body {
    font-family: Lato, Helvetica, sans-serif;
}

.modal-open {
    overflow: hidden;
}

input:focus, button:focus {
    outline: none;
}

.App {
    margin-bottom: 20em;
}

.app-title-dark {
    text-align: center;
    font-weight: 400;
    font-size: 1.2em;
    font-family: Lato;
    letter-spacing: 2px;
    word-spacing: 6px;
    color: #fff;
    margin: 0;
    padding-top: 2.4em;
    cursor: default;
}

.app-title {
  text-align: center;
  font-weight: 400;
  font-size: 1.2em;
  font-family: Lato;
  letter-spacing: 2px;
  word-spacing: 6px;
  color: #676767;
  margin: 0;
  padding-top: 2.4em;
  cursor: default;
}

input,
textarea,
button,
select,
div,
a {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.dark-App {
    animation: darkBackground 1s;
    background: #171717;
}

.App {
    /* animation: lightBackground 1s; */
}


/* Night Mode Theme animations */

@keyframes darkBackground {
    from{
      background: #fff
    } 
    to{
      background: #171717;
    }
}
  
@keyframes lightBackground {
    from{
      background: #171717
    } 
    to{
      background: #fff;
    }
}