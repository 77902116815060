
.theme-container {
  width: 50px;
  height: 35px;
  position: absolute;
  top: 1em;
  left: 2em;    
  padding: 10px;
  box-sizing: border-box;
  box-shadow: 0px 1px 3px 0px #171717;
  border-radius: 30px;
  overflow: hidden;
  background: #f9f9f9;
  animation: lightBackground 1s;
}

.theme-container-dark{
  width: 50px;
  height: 35px;
  position: absolute;
  top: 1em;
  left: 2em;    
  padding: 10px;
  box-sizing: border-box;
  box-shadow: 0px 1px 3px 0px #ccc;
  border-radius: 30px;
  overflow: hidden;
  background: #1e1e1e;
  animation: darkBackground 1s;
}

.theme-button {
  width: 15px;
  height: 15px;
  background: #171717;
  box-shadow:0px 0px 1px 1px #b3b3b3;   
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  left: 0;
  animation: darkTheme 1s;
}

.theme-button-light {
  width: 15px;
  height: 15px;
  background: #171717;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  left: 18px;
  background: #fff;
  box-shadow: 0px 1px 3px 0px #cacaca;
  animation: lightTheme 1s;
}


@keyframes lightTheme{
  from{
      left: 0;
      background: #171717;
  } 
  to{
      left: 18px;
      background: #fff
  }
}

@keyframes darkTheme{
  from{
      left: 18px;
      background: #fff
  } 
  to{
      left: 0;
      background: #171717;

  }
}
