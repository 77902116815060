.search-container {
    text-align: center;
}

.input-container {
    display: inline-block;
    max-width: 100%;
    margin: 3em auto;
    margin-top: 0;
}

.dark-search-input {
    background: #171717;
    color: white;
    width: 540px;
    max-width: 65%;
    box-sizing: border-box;
    padding: 10px;
    font-size: 1.35em;
    border: 1px solid #808080;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    animation: darkTheme 1s;
}

.dark-search-input::placeholder {
    color: #f0f0f0;
}

.search-input {
    width: 540px;
    max-width: 65%;
    box-sizing: border-box;
    padding: 10px;
    font-size: 1.35em;
    border: 1px solid #eaeaea;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.dark-submit-button {
    box-sizing: border-box;
    max-width: 35%;
    padding: 10px 20px;
    font-size: 1.35em;
    font-weight: bold;
    text-transform: uppercase;
    color: #fff;
    background: #171717;
    border: 1px solid #808080;
    cursor: pointer;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    animation: darkTheme 1s;
}

.submit-button {
    box-sizing: border-box;
    max-width: 35%;
    padding: 10px 20px;
    font-size: 1.35em;
    font-weight: bold;
    text-transform: uppercase;
    background: #fff;
    border: 1px solid #eaeaea;
    color: #8a8a8a;
    cursor: pointer;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.submit-button:hover {
    background: #5290d4;
    color: white;
}

.attribution {
    text-transform: lowercase;
    color: #8a8a8a;
    padding-left: 10px;
    padding-top: 4px;
    font-size: .85em;
    display: inline-block;
}

.dark-attribution {
    text-transform: lowercase;
    color: #fff;
    padding-left: 10px;
    padding-top: 4px;
    font-size: .85em;
    display: inline-block;
}

.dark-attribution a {text-decoration: none; color: #fff;}

.attribution a {
    text-decoration: none;
    color: #8a8a8a;
}

.attribution a:visited {
    color: #8a8a8a;
}

.attribution a:hover {
    color: #52a2e2;
}
