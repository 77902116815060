.pages-list {
    width: 400px;
    max-width: 96vw;
    margin: 0 auto;
    text-align: center;
    box-sizing: border-box;
    padding: 0;
}

.page-number {
    list-style: none;
    display: inline-block;
    margin: 10px;
    font-weight: bold;
    cursor: pointer;
    color: #cacaca;
}

.active-page {
    color: #2196f3;
}

.page-number:hover {
    color: #4d95cf9a;
}