.language-list {
    text-align: center;
    padding: 0;
}

.dark-list-region {
    display: inline;
    list-style: none;
    padding: 2px 7px;
    color: #fff;
    background: #171717;
    font-size: .8em;
    margin: 0 10px;
    border: 1px solid #eee;
    border-radius: 5px;
    cursor: pointer;
    animation: darkBackground 1s;
}

.list-region {
    display: inline;
    list-style: none;
    padding: 2px 7px;
    color: #777;
    font-size: .8em;
    margin: 0 10px;
    border: 1px solid #eee;
    border-radius: 5px;
    cursor: pointer;
}

.list-region::selection{
    outline: none;
}

.active-region {
    color: #fff;
    border-color: #f97b7b;
    background: #f97b7b;
    font-weight: bold;
}