.dark-article-preview-container {
    width: 800px;
    max-width: 90vw;
    max-height: 82vh;
    overflow: scroll;
    background: #171717;
    border-radius: 9px;
    box-shadow: 0 0 0em 1px #121212;
    position: fixed;
    left: 50%;
    top: 8%;
    transform: translate(-50%, 0);
}

.article-preview-container {
    width: 800px;
    max-width: 90vw;
    max-height: 82vh;
    overflow: scroll;
    background: #fdfdfd;
    border-radius: 9px;
    box-shadow: 0 0 12em 1em #a5a5a536;
    position: fixed;
    left: 50%;
    top: 8%;
    transform: translate(-50%, 0);
}

.close-button {
    position: absolute;
    top: 70px;
    right: 20px;
    font-weight: bold;
    color: white;
    font-size: 1.2em;
    color: red;
    margin-top: -39px;
    cursor: pointer;
}

.preview-img-container {
    width: 100%;
    height: 250px;
    overflow: hidden;
}

.preview-img {
    width: 100%;
    margin-top: -70px;
}

.preview-author, .dark-preview-author {
    display: inline-block;
    padding: 10px 20px 15px 20px;
    float: left;
    font-size: .9em;
    text-transform: capitalize;
}

.preview-author a {
    text-decoration: none;
    color: #3f3f3f;
    font-weight: bold;
}

.dark-preview-author a {
    text-decoration: none;
    color: #fff;
    font-weight: bold;
}

.preview-date {
    display: inline-block;
    padding: 10px 20px;
    float: right;
    font-weight: 600;
    font-size: .85em;
    color: #9a9a9a;
}

.dark-preview-title {
    font-size: 1.9em;
    padding: 40px;
    padding-top: 0;
    padding-bottom: 0;
    font-weight: 500;
    text-align: left;
    color: #fff;
}

.preview-title {
    font-size: 1.9em;
    padding: 40px;
    padding-top: 0;
    padding-bottom: 0;
    font-weight: 500;
    text-align: left;
    color: #333;
}

.dark-preview-content {
    width: 500px;
    text-align: left;
    margin: 0 auto;
    max-width: 80%;
    font-size: 1.25em;
    font-weight: 400;
    font-family: Helvetica;
    letter-spacing: .5px;
    word-spacing: 2px;
    line-height: 45px;
    color: #fff;
}

.preview-content {
    width: 500px;
    text-align: left;
    margin: 0 auto;
    max-width: 80%;
    font-size: 1.25em;
    font-weight: 400;
    font-family: Helvetica;
    letter-spacing: .5px;
    word-spacing: 2px;
    line-height: 45px;
    color: #333333e8;
}

.preview-link {
    text-decoration: none;
    font-size: 1em;
    color: #0378d6;
    text-transform: lowercase;
    font-weight: 500;
}

.dark-preview-source {
    text-align: left;
    padding: 10px 20px;
    border-top: 1px solid #333;
    color: #fff;
    font-weight: 500;
    font-size: 1.1em;
    margin-top: 3em;
}

.preview-source {
    text-align: left;
    padding: 10px 20px;
    border-top: 1px solid #eee;
    color: #676767;
    font-weight: 500;
    font-size: 1.1em;
    margin-top: 3em;
}

@media only screen and (max-width: 600px) {
    .preview-content {
        font-size: 1em;
        font-weight: 400;
        line-height: 31px;
    }

    .preview-img-container {
        height: 160px;
    }

    .preview-img {
        margin: 0;
    }

    .preview-title {
        font-size: 1.35em;
    }
}