.dark-sources-selector-container {
    width: 500px;
    max-height: 60vh;
    position: fixed;
    right: 4em;
    border-radius: 10px;
    background: #171717;
    overflow: scroll;
    box-shadow: 0 0 0 1px #808080;
    text-align: left;
    animation: darkBackground 1s;
}

.sources-selector-container {
    width: 500px;
    max-height: 60vh;
    position: fixed;
    right: 4em;
    border-radius: 10px;
    background: #fdfdfd;
    overflow: scroll;
    box-shadow: 0 0 0 1px #eaeaea;
    text-align: left;
}

.sources-slider {
}

.sources-list {
    padding-left: 9px;
}

.dark-source-item {
    list-style: none;
    display: inline-block;
    border: 1px solid #808080;
    color: #fff;
    font-weight: bold;
    padding: 10px;
    margin: 10px;
    cursor: pointer;
    border-radius: 15px;
}

.source-item {
    list-style: none;
    display: inline-block;
    border: 1px solid #efefef;
    color: #6a6a6a;
    font-weight: bold;
    padding: 10px;
    margin: 10px;
    cursor: pointer;
    border-radius: 15px;
}

.source-item-active {
    border-color: #000;
    background: #000;
    color: white;
}


@media only screen and (max-width: 1759px) {
    .articles {
        margin-left: -10em;
    }
}

@media only screen and (max-width: 1600px) {
    .articles {
        margin-left: -20em;
    }
}

@media only screen and (max-width: 1440px) {
    .articles {
        margin-left: -30em;
    }
}

@media only screen and (max-width: 1290px) {
    .articles {
        margin-left: -35em;
    }
}

@media only screen and (max-width: 1290px) {
    .articles {
        margin-left: auto;
    }

    .dark-sources-selector-container {
        background: #171717;
        width: 900px;
        height: 90px;
        max-width: 95%;
        position: relative;
        padding: 0;
        border-radius: 10px;
        overflow: hidden;
        overflow-x: scroll;
        margin: 2.6em auto;
        margin-top: 0;
        right: 0;
    }
    
    .sources-selector-container {
        width: 900px;
        height: 90px;
        max-width: 95%;
        position: relative;
        padding: 0;
        border-radius: 10px;
        background: #fdfdfd;
        overflow: hidden;
        overflow-x: scroll;
        margin: 2.6em auto;
        margin-top: 0;
        right: 0;
    }

    .sources-slider {
        width: 8000px;
    }
}

@media only screen and (min-width: 1025px) {

    .source-item:hover {
        background: #000;
        border-color: #000;
        color: white;
    }
    
}