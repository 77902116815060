@import url(https://fonts.googleapis.com/css?family=Lato:400,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,500);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
    font-family: Lato, Helvetica, sans-serif;
}

.modal-open {
    overflow: hidden;
}

input:focus, button:focus {
    outline: none;
}

.App {
    margin-bottom: 20em;
}

.app-title-dark {
    text-align: center;
    font-weight: 400;
    font-size: 1.2em;
    font-family: Lato;
    letter-spacing: 2px;
    word-spacing: 6px;
    color: #fff;
    margin: 0;
    padding-top: 2.4em;
    cursor: default;
}

.app-title {
  text-align: center;
  font-weight: 400;
  font-size: 1.2em;
  font-family: Lato;
  letter-spacing: 2px;
  word-spacing: 6px;
  color: #676767;
  margin: 0;
  padding-top: 2.4em;
  cursor: default;
}

input,
textarea,
button,
select,
div,
a {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.dark-App {
    -webkit-animation: darkBackground 1s;
            animation: darkBackground 1s;
    background: #171717;
}

.App {
    /* animation: lightBackground 1s; */
}


/* Night Mode Theme animations */

@-webkit-keyframes darkBackground {
    from{
      background: #fff
    } 
    to{
      background: #171717;
    }
}

@keyframes darkBackground {
    from{
      background: #fff
    } 
    to{
      background: #171717;
    }
}
  
@-webkit-keyframes lightBackground {
    from{
      background: #171717
    } 
    to{
      background: #fff;
    }
}
  
@keyframes lightBackground {
    from{
      background: #171717
    } 
    to{
      background: #fff;
    }
}
.news-container {
    text-align: center;
    font-family: Roboto, Arial, Helvetica, sans-serif;
}

.no-articles {
    width: 250px;
    max-width: 85%;
    box-sizing: border-box;
    margin: 4em auto;
    background: #fdfdfd;
    border: 1px solid #eaeaea;
    text-align: center;
    padding: 20px;
    text-transform: uppercase;
    color: #888;
    font-weight: 600;
    letter-spacing: .7px;
    border-radius: 5px;
}

.articles, .article-preview-container {
    display: inline-block;
    position: relative;
}

.articles {
    text-align: left;
}

.hide {
    display: none;
}

.dark-background {
    width: 100vw;
    height: 100vh;
    position: fixed;
    background: #000000de;
    top: 0;
    left: 0;
}
.dark-article-container {
    width: 600px;
    max-width: 95vw;
    margin: 30px auto;
    border-radius: 9px;
    border-bottom-left-radius: 0;
    box-shadow: 0 0 1px 0px #9c9c9c;
    background: #171717;
    color: #333;
    -webkit-animation: darkTheme 1s;
            animation: darkTheme 1s;
}

.article-container {
    width: 600px;
    max-width: 95vw;
    margin: 30px auto;
    border-radius: 9px;
    border-bottom-left-radius: 0;
    box-shadow: 0 0 1px 0px #9c9c9c;
    background: #fefefe;
    color: #333;
}

.article-container:first-child, .dark-article-container:first-child  {
    margin-top: 0;
}

.article-container:hover {
    box-shadow: 0 0 4px 0px #e0e0e0;
}

.dark-article-source {
    float: left;
    font-weight: 500;
    font-size: .85em;
    color: #fff;
    background: #171717;
    -webkit-animation: darkBackground 1s;
            animation: darkBackground 1s;
    padding: 7px 15px;
    border-right: 1px solid #4f4f4f;
    border-bottom: 1px solid #4f4f4f;
    border-top-left-radius: 9px;
    border-bottom-right-radius: 9px;
}

.article-source {
    float: left;
    font-weight: 500;
    font-size: .85em;
    color: #000;
    background: #ffffff;
    padding: 7px 15px;
    border-right: 1px solid #efefef;
    border-bottom: 1px solid #efefef;
    border-top-left-radius: 9px;
    border-bottom-right-radius: 9px;
}

.article-date {
    padding: 10px 20px;
    float: right;
    font-weight: 600;
    font-size: .85em;
    color: #9a9a9a;
}

.article-content-container {
    padding-left:10px; 
    box-sizing: border-box
}

.dark-img-container, .img-container, .title-description-container {
    display: inline-block;
    vertical-align: middle;
}

.dark-img-container {
    width: 30%;
    height: 122px;
    box-sizing: border-box;
    border: 1px solid #171717;
    border-radius: 4px;
    overflow: hidden;
    background-size: cover
}

.img-container {
    width: 30%;
    height: 122px;
    box-sizing: border-box;
    border: 1px solid #eeee;
    border-radius: 4px;
    overflow: hidden;
    background-size: cover
}

.title-description-container{
    width: 70%;
}

.title-description-container-no-img {
    width: 100%;
}

.article-img {
    max-width: 100%;
}

.dark-article-title {
    font-weight: 500;
    font-family: Arial, Helvetica, sans-serif;
    margin: 0;
    padding: 5px 20px;
    padding-bottom: 0;
    color: #fff;
    cursor: pointer;
}

.article-title {
    font-weight: 500;
    font-family: Arial, Helvetica, sans-serif;
    margin: 0;
    padding: 5px 20px;
    padding-bottom: 0;
    color: #333;
    cursor: pointer;
}

.dark-article-description {
    font-weight: 300;
    padding: 2px 20px;
    font-size: .99em;
    line-height: 22px;
    cursor: pointer;
    color: #fafafa;
    margin-bottom: 0;
}

.article-description {
    font-weight: 300;
    padding: 2px 20px;
    font-size: .99em;
    line-height: 22px;
    cursor: pointer;
    color: #666;
    margin-bottom: 0;
}

.article-author {
    display: inline-block;
    padding: 10px 20px 15px 20px;
    float: left;
    font-size: .9em;
    text-transform: capitalize;
}

.author-icon {
    width: 20px;
    margin-bottom: -4px;
}

.dark-article-url {
    display: inline-block;
    padding: 10px 20px 15px 20px;
    float: right;
    font-weight: 600;
    font-size: .85em;
}

.dark-article-url a {
    text-decoration: none;
    font-weight: bold;
    color: #fff;
}

.article-url {
    display: inline-block;
    padding: 10px 20px 15px 20px;
    float: right;
    font-weight: 600;
    font-size: .85em;
}

.article-url a {
    text-decoration: none;
    font-weight: bold;
    color: #3e89c7;
}

.article-author a {
    text-decoration: none;
    font-weight: 500;
    color: #9e9e9e;
}

@media only screen and (max-width: 575px) {
    .article-content-container {
        padding:0px; 
        box-sizing: border-box
    }
    
    .dark-img-container, .img-container, .title-description-container {
        display: block;
        vertical-align: top;
    }
    
    .dark-img-container {
        width: 100%;
        margin: 0 auto;
        height: 145px;
        box-sizing: border-box;
        border: 1px solid #171717;
        border-radius: 4px;
        overflow: hidden;
        background-size: cover;
        background-position: center;
    }
    
    .img-container {
        width: 100%;
        margin: 0 auto;
        height: 145px;
        box-sizing: border-box;
        border: 1px solid #eeee;
        border-radius: 4px;
        overflow: hidden;
        background-size: cover;
        background-position: center;
    }
    
    .title-description-container{
        width: 100%;
    }
}
.dark-article-preview-container {
    width: 800px;
    max-width: 90vw;
    max-height: 82vh;
    overflow: scroll;
    background: #171717;
    border-radius: 9px;
    box-shadow: 0 0 0em 1px #121212;
    position: fixed;
    left: 50%;
    top: 8%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
}

.article-preview-container {
    width: 800px;
    max-width: 90vw;
    max-height: 82vh;
    overflow: scroll;
    background: #fdfdfd;
    border-radius: 9px;
    box-shadow: 0 0 12em 1em #a5a5a536;
    position: fixed;
    left: 50%;
    top: 8%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
}

.close-button {
    position: absolute;
    top: 70px;
    right: 20px;
    font-weight: bold;
    color: white;
    font-size: 1.2em;
    color: red;
    margin-top: -39px;
    cursor: pointer;
}

.preview-img-container {
    width: 100%;
    height: 250px;
    overflow: hidden;
}

.preview-img {
    width: 100%;
    margin-top: -70px;
}

.preview-author, .dark-preview-author {
    display: inline-block;
    padding: 10px 20px 15px 20px;
    float: left;
    font-size: .9em;
    text-transform: capitalize;
}

.preview-author a {
    text-decoration: none;
    color: #3f3f3f;
    font-weight: bold;
}

.dark-preview-author a {
    text-decoration: none;
    color: #fff;
    font-weight: bold;
}

.preview-date {
    display: inline-block;
    padding: 10px 20px;
    float: right;
    font-weight: 600;
    font-size: .85em;
    color: #9a9a9a;
}

.dark-preview-title {
    font-size: 1.9em;
    padding: 40px;
    padding-top: 0;
    padding-bottom: 0;
    font-weight: 500;
    text-align: left;
    color: #fff;
}

.preview-title {
    font-size: 1.9em;
    padding: 40px;
    padding-top: 0;
    padding-bottom: 0;
    font-weight: 500;
    text-align: left;
    color: #333;
}

.dark-preview-content {
    width: 500px;
    text-align: left;
    margin: 0 auto;
    max-width: 80%;
    font-size: 1.25em;
    font-weight: 400;
    font-family: Helvetica;
    letter-spacing: .5px;
    word-spacing: 2px;
    line-height: 45px;
    color: #fff;
}

.preview-content {
    width: 500px;
    text-align: left;
    margin: 0 auto;
    max-width: 80%;
    font-size: 1.25em;
    font-weight: 400;
    font-family: Helvetica;
    letter-spacing: .5px;
    word-spacing: 2px;
    line-height: 45px;
    color: #333333e8;
}

.preview-link {
    text-decoration: none;
    font-size: 1em;
    color: #0378d6;
    text-transform: lowercase;
    font-weight: 500;
}

.dark-preview-source {
    text-align: left;
    padding: 10px 20px;
    border-top: 1px solid #333;
    color: #fff;
    font-weight: 500;
    font-size: 1.1em;
    margin-top: 3em;
}

.preview-source {
    text-align: left;
    padding: 10px 20px;
    border-top: 1px solid #eee;
    color: #676767;
    font-weight: 500;
    font-size: 1.1em;
    margin-top: 3em;
}

@media only screen and (max-width: 600px) {
    .preview-content {
        font-size: 1em;
        font-weight: 400;
        line-height: 31px;
    }

    .preview-img-container {
        height: 160px;
    }

    .preview-img {
        margin: 0;
    }

    .preview-title {
        font-size: 1.35em;
    }
}
.search-container {
    text-align: center;
}

.input-container {
    display: inline-block;
    max-width: 100%;
    margin: 3em auto;
    margin-top: 0;
}

.dark-search-input {
    background: #171717;
    color: white;
    width: 540px;
    max-width: 65%;
    box-sizing: border-box;
    padding: 10px;
    font-size: 1.35em;
    border: 1px solid #808080;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    -webkit-animation: darkTheme 1s;
            animation: darkTheme 1s;
}

.dark-search-input::-webkit-input-placeholder {
    color: #f0f0f0;
}

.dark-search-input::-ms-input-placeholder {
    color: #f0f0f0;
}

.dark-search-input::placeholder {
    color: #f0f0f0;
}

.search-input {
    width: 540px;
    max-width: 65%;
    box-sizing: border-box;
    padding: 10px;
    font-size: 1.35em;
    border: 1px solid #eaeaea;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.dark-submit-button {
    box-sizing: border-box;
    max-width: 35%;
    padding: 10px 20px;
    font-size: 1.35em;
    font-weight: bold;
    text-transform: uppercase;
    color: #fff;
    background: #171717;
    border: 1px solid #808080;
    cursor: pointer;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    -webkit-animation: darkTheme 1s;
            animation: darkTheme 1s;
}

.submit-button {
    box-sizing: border-box;
    max-width: 35%;
    padding: 10px 20px;
    font-size: 1.35em;
    font-weight: bold;
    text-transform: uppercase;
    background: #fff;
    border: 1px solid #eaeaea;
    color: #8a8a8a;
    cursor: pointer;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.submit-button:hover {
    background: #5290d4;
    color: white;
}

.attribution {
    text-transform: lowercase;
    color: #8a8a8a;
    padding-left: 10px;
    padding-top: 4px;
    font-size: .85em;
    display: inline-block;
}

.dark-attribution {
    text-transform: lowercase;
    color: #fff;
    padding-left: 10px;
    padding-top: 4px;
    font-size: .85em;
    display: inline-block;
}

.dark-attribution a {text-decoration: none; color: #fff;}

.attribution a {
    text-decoration: none;
    color: #8a8a8a;
}

.attribution a:visited {
    color: #8a8a8a;
}

.attribution a:hover {
    color: #52a2e2;
}

.dark-sources-selector-container {
    width: 500px;
    max-height: 60vh;
    position: fixed;
    right: 4em;
    border-radius: 10px;
    background: #171717;
    overflow: scroll;
    box-shadow: 0 0 0 1px #808080;
    text-align: left;
    -webkit-animation: darkBackground 1s;
            animation: darkBackground 1s;
}

.sources-selector-container {
    width: 500px;
    max-height: 60vh;
    position: fixed;
    right: 4em;
    border-radius: 10px;
    background: #fdfdfd;
    overflow: scroll;
    box-shadow: 0 0 0 1px #eaeaea;
    text-align: left;
}

.sources-slider {
}

.sources-list {
    padding-left: 9px;
}

.dark-source-item {
    list-style: none;
    display: inline-block;
    border: 1px solid #808080;
    color: #fff;
    font-weight: bold;
    padding: 10px;
    margin: 10px;
    cursor: pointer;
    border-radius: 15px;
}

.source-item {
    list-style: none;
    display: inline-block;
    border: 1px solid #efefef;
    color: #6a6a6a;
    font-weight: bold;
    padding: 10px;
    margin: 10px;
    cursor: pointer;
    border-radius: 15px;
}

.source-item-active {
    border-color: #000;
    background: #000;
    color: white;
}


@media only screen and (max-width: 1759px) {
    .articles {
        margin-left: -10em;
    }
}

@media only screen and (max-width: 1600px) {
    .articles {
        margin-left: -20em;
    }
}

@media only screen and (max-width: 1440px) {
    .articles {
        margin-left: -30em;
    }
}

@media only screen and (max-width: 1290px) {
    .articles {
        margin-left: -35em;
    }
}

@media only screen and (max-width: 1290px) {
    .articles {
        margin-left: auto;
    }

    .dark-sources-selector-container {
        background: #171717;
        width: 900px;
        height: 90px;
        max-width: 95%;
        position: relative;
        padding: 0;
        border-radius: 10px;
        overflow: hidden;
        overflow-x: scroll;
        margin: 2.6em auto;
        margin-top: 0;
        right: 0;
    }
    
    .sources-selector-container {
        width: 900px;
        height: 90px;
        max-width: 95%;
        position: relative;
        padding: 0;
        border-radius: 10px;
        background: #fdfdfd;
        overflow: hidden;
        overflow-x: scroll;
        margin: 2.6em auto;
        margin-top: 0;
        right: 0;
    }

    .sources-slider {
        width: 8000px;
    }
}

@media only screen and (min-width: 1025px) {

    .source-item:hover {
        background: #000;
        border-color: #000;
        color: white;
    }
    
}
/* L o a d e r s*/
/* loader 1 */
.loader-container{
    background: rgba(255, 255, 255, 0);
    position: fixed;
    top: 60%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border: 4px solid #e4e4e49c;
  border-left: 4px solid #fff;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.language-list {
    text-align: center;
    padding: 0;
}

.dark-list-region {
    display: inline;
    list-style: none;
    padding: 2px 7px;
    color: #fff;
    background: #171717;
    font-size: .8em;
    margin: 0 10px;
    border: 1px solid #eee;
    border-radius: 5px;
    cursor: pointer;
    -webkit-animation: darkBackground 1s;
            animation: darkBackground 1s;
}

.list-region {
    display: inline;
    list-style: none;
    padding: 2px 7px;
    color: #777;
    font-size: .8em;
    margin: 0 10px;
    border: 1px solid #eee;
    border-radius: 5px;
    cursor: pointer;
}

.list-region::-moz-selection{
    outline: none;
}

.list-region::selection{
    outline: none;
}

.active-region {
    color: #fff;
    border-color: #f97b7b;
    background: #f97b7b;
    font-weight: bold;
}
.pages-list {
    width: 400px;
    max-width: 96vw;
    margin: 0 auto;
    text-align: center;
    box-sizing: border-box;
    padding: 0;
}

.page-number {
    list-style: none;
    display: inline-block;
    margin: 10px;
    font-weight: bold;
    cursor: pointer;
    color: #cacaca;
}

.active-page {
    color: #2196f3;
}

.page-number:hover {
    color: #4d95cf9a;
}

.theme-container {
  width: 50px;
  height: 35px;
  position: absolute;
  top: 1em;
  left: 2em;    
  padding: 10px;
  box-sizing: border-box;
  box-shadow: 0px 1px 3px 0px #171717;
  border-radius: 30px;
  overflow: hidden;
  background: #f9f9f9;
  -webkit-animation: lightBackground 1s;
          animation: lightBackground 1s;
}

.theme-container-dark{
  width: 50px;
  height: 35px;
  position: absolute;
  top: 1em;
  left: 2em;    
  padding: 10px;
  box-sizing: border-box;
  box-shadow: 0px 1px 3px 0px #ccc;
  border-radius: 30px;
  overflow: hidden;
  background: #1e1e1e;
  -webkit-animation: darkBackground 1s;
          animation: darkBackground 1s;
}

.theme-button {
  width: 15px;
  height: 15px;
  background: #171717;
  box-shadow:0px 0px 1px 1px #b3b3b3;   
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  left: 0;
  -webkit-animation: darkTheme 1s;
          animation: darkTheme 1s;
}

.theme-button-light {
  width: 15px;
  height: 15px;
  background: #171717;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  left: 18px;
  background: #fff;
  box-shadow: 0px 1px 3px 0px #cacaca;
  -webkit-animation: lightTheme 1s;
          animation: lightTheme 1s;
}


@-webkit-keyframes lightTheme{
  from{
      left: 0;
      background: #171717;
  } 
  to{
      left: 18px;
      background: #fff
  }
}


@keyframes lightTheme{
  from{
      left: 0;
      background: #171717;
  } 
  to{
      left: 18px;
      background: #fff
  }
}

@-webkit-keyframes darkTheme{
  from{
      left: 18px;
      background: #fff
  } 
  to{
      left: 0;
      background: #171717;

  }
}

@keyframes darkTheme{
  from{
      left: 18px;
      background: #fff
  } 
  to{
      left: 0;
      background: #171717;

  }
}

